let Config = {
  // url: "http://localhost:3002/"
  // url:
  url:
    //http://81260fb6-default-testalb-7402-960723735.us-east-1.elb.amazonaws.com///preprod
    "https://dev-api.economicfunding.com/" //dev env
  // "http://81260fb6-default-devalb-a4b5-1707230773.us-east-1.elb.amazonaws.com/"//test
};

export default Config;
